import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "global.home": "Home",
	  "global.cart": "Cart",
	  "global.profile": "Profile",
	  "global.item": "item",
	  "global.items": "items",
	  "global.cgv": "Terms of Service",
	  "global.cgu": "Privacy Policy",
	  "global.deliveryAddress": "Delivery address",
	  "global.deliveryFee": "Delivery fee",
	  "global.deliveryIn": "Delivery in",
	  "global.deliveryAt": "Delivery at",
	  "global.deliveryScheduled": "Delivery scheduled",
	  "global.dateAt": "at",
	  "global.dateBackAt": "Back at",
	  "global.dateBack": "Back",
	  "global.serviceClosed": "Closed",
	  "global.serviceClosedExcep": "Exceptionally closed",
	  "global.errorDeliveryTime": "Unable to calculate delivery time",
	  "global.addressOutsideRange": "Adresse en dehors de la zone de livraison",
	  "global.delivery": "Delivery",
	  "global.orderNum": "Order n°",
	  "global.contactSupport": "Contact customer support",
	  "global.pageRootTitle": "SOS Soirée",
	  "global.pageDefaultTitle": "Drinks and snacks delivered at your door in " + process.env.REACT_APP_CITY,
	  "global.metaDescription": "Drinks and snacks delivery in " + process.env.REACT_APP_CITY + ". Alcohols, beers, wines, soft drinks and snacks delivered directly at your door!",
	  "form.firstname": "Firstname",
	  "form.lastname": "Lastname",
	  "form.phone": "Phone number",
	  "footer.order": ["How to", "order?"],
	  "menu.titleOur": "Our",
	  "menu.titleDrinks": "drinks",
	  "menu.titleDelivered": "delivered",
	  "menu.titleToYou": "to you",
	  "menu.ourProducts": "Our products",
	  "menu.alcohol": "Alcohol sale is forbidden to minors under 18, people visibly drunk, on public area. Drink responsably",
	  "home.description": "Need some <b>drinks delivered in" + process.env.REACT_APP_CITY + "</b>? Nothing more simple with <b>SOS Soirée</b>! Pick amoung our large selection of alcohols, beers, wines, soft drinks and snacks: <b>your drink order will be delivered</b> night and day <b>within 30 minutes in " + process.env.REACT_APP_CITY + "</b> and suroundings!",
	  "home.categoryMetaDescription": "{{categoryName}} delivery in " + process.env.REACT_APP_CITY,
	  "home.productMetaDescription": "{{productName}} delivery in " + process.env.REACT_APP_CITY,
	  "cart.title": "Cart",
	  "cart.my_cart": "My cart",
	  "cart.total_products": "Total products",
	  "cart.total": "Total",
	  "cart.total_delivery": "Delivery fee",
	  "cart.total_discount": "Discount",
	  "cart.proceed": "Proceed",
	  "cart.your_cart_is_empty": "This cart is empty. Too empty.",
	  "product.out_of_stock": "Out of stock",
	  "product.seeCart": "See the cart",
	  "product.addedToCart": "Added to the cart!",
	  "addToCart.add": "Add to cart",
	  "login.welcome": "Welcome to SOS Soirée",
	  "login.enterPhone": "Enter your phone number to Login/Sign up",
	  "login.enterCode": "Enter the code sent to your phone",
	  "login.enterInfos": "A few more information 🙏",
	  "login.accept": "By continuing, you agree to our",
	  "login.resend": "Resend",
	  "login.resendIn": "in",
	  "login.resendSecs": "seconds",
	  "login.errorCodeInvalid": "The code entered is incorrect",
	  "login.errorCodeExpired": "This code has expired, please tap on resend",
	  "login.errorCodeAlready": "This code has already been verified, please tap on resend",
	  "login.errorPhoneMobile": "Please enter a valid mobile phone number",
	  "login.loadingDefault": "Just a few seconds and then: APERO",
	  "login.loadingCustomer": "Nice to see you {{firstname}}! hold on while we log you in",
	  "deliveryAddress.title": "Delivery address",
	  "deliveryAddress.switchAddress": "Switch to an other address",
	  "deliveryAddress.addAddress": "Add an address",
	  "deliveryAddress.errorNoAddress": "Please select or add an address to continue",
	  "myAddresses.title": "My addresses",
	  "myAddresses.addAddress": "Add a new address",
	  "addAddress.address": "Address",
	  "addAddress.deliveryNotes": "Useful information for the driver",
	  "addAddress.titleAdd": "New address",
	  "addAddress.titleEdit": "Edit an address",
	  "addAddress.subtitle": "More precise are the informations, more smooth will the delivery be ;-)",
	  "payment.title": "Payment",
	  "payment.paymentMeans": "Payment means",
	  "payment.proceed": "Confirm payment",
	  "payment.new": "New",
	  "payment.cgv": "I read and I accept the",
	  "payment.alcohol": "Alcohol sale is forbidden to minors under 18, people visibly drunk, on public area. By continuing, you certify that you fulfill those conditions and will be able to prove your identity at delivery.",
	  "payment.acceptCgv": "Please accept terms of service to continue",
	  "payment.deliveryTimeChanged": "Time of delivery has changed. Please check and validate again if it's OK for you",
	  "payment.serviceClosed": "Our service is currently closed, order is unavailable for the moment",
	  "payment.orderUnavailable": "Order is unavailable for the moment, please try again later",
	  "payment.paymentRefused": "Payment was rejected, please try again",
	  "payment.paymentTimeout": "Payment canceled due to inactivity, please try again",
	  "payment.paymentNotAvailable": "This payment is not avaiblable yet. We work very very hard to get it live as soon as possible 🥵",
	  "payment.pleaseWait": "Loading in progress, please do not leave this page",
	  "payment.qa": {"q": "Why is it not possible to pay at arrival?", "a": "To garantee the safety of the drivers, only online payment is available. If you prefer, you can make your order on a delivery plateform:", "l": {"val": "See other way to make an order"}},
	  "orderDetail.title": "My order",
	  "orderDetail.orderReceived": "Thank you for your order!",
	  "contact.title": "Contact us",
	  "contact.subtitle": "Our team will be back at you in 48h tops (on workdays).",
	  "contact.sentTitle": "We successfully received your message, thanks.",
	  "contact.sentSubtitle": "Our team will get back at you shortly",
	  "contact.faqTitle": "Your answer may already be there!",
	  "contact.faqInfos": "Consult our FAQ",
	  "faq.title": "FAQ",
	  "faq.qa": {
		"Products": [
			{"q": "What kind of products do you offer?", "a": "We sell and deliver all the essentials for a successfull party: alcohol, non alcoholic beverages, wine, beer and some 'apéro' snacks", "l": {"val": "See all the offer", "to": "/"}},
			{"q": "Do the products arrive cold?", "a": "Yes, all our products come at the right temperature to be consumed directly on arrival"},
			{"q": "Do you sell cigarets?", "a": "No, we're not allowed to deliver tobacco"},
			{"q": "What are your opening hours?", "a": "", "l": {"val": "See opening hours", "data-bs-toggle": "modal", "data-bs-target": "#hours"}},
		],
		"Delivery": [
			{"q": "What is the time delay to be delivered?", "a": "Delivery time is usually around 10-15min but it can vary upon your address, demand and traffic. Fill in your address and we'll announce you the estimated time of delivery before payment"},
			{"q": "What is the delivery area?", "a": "We deliver a 10-15km radius around Annecy. The delivery area can vary upoun the demand, traffic or weather conditions."},
			{"q": "How can I know if you deliver to my address?", "a": "Fill in your address after the cart step and you'll be notified if your address is not in the delivery area"},
		],
		"Payment": [
			{"q": "What payment method do you accept?", "a": "We accept all the classic credit/debit card on the market. Only online payment is available"},
			{"q": "Is it possible to pay cash?", "a": "For the safety of our drivers, only online payment by card is available"},
		],
		"Order": [
			{"q": "Do I need to be 18 to order?", "a": "If you intent to order alcohol, yes. The driver will be asking for an ID to give you your order"},
			{"q": "Do I need to be inside to order?", "a": "If you intent to order alcohol, yes. No alcohol delivery can be made outside of a building (appartment, hotel, etc.)"},
			{"q": "Is it possible to order by phone?", "a": "Ordering is only possible online. We're also on the delivery plateforms Deliveroo and Uber Eats"},
		],
		"Cancellation": [
			{"q": "Is it possible to cancel on order?", "a": "Once the order is made and paid, it's not possible to cancel it, sorry"},
			{"q": "What happens if I'm not at home when the driver arrives?", "a": "The driver will be waiting 10 minutes after arrival and try to contact you several times. If you're not reachable is this time frame, the delivery will be canceled. You'll be charged for the delivery fees + €5 cancellation fees."},
			{"q": "What should I do if my order hasn't be delivered?", "a": "In some rare cases, it's possible that we're not able to deliver your order (technical issue, unavailable products, no driver available, etc.). If that shoud happen, no action is required, your order will be cancelled and you'll be refunded automatically"},
		],
	  },
	  "faq.contactTitle": "You didn't find your answer?",
	  "faq.contactInfos": "Contact us",
	  "order.title": "How to make an order?",
	  "order.online.title": "Right here online",
	  "order.online.content": [
		"The simpliest way. Fast delivery, no service fee",
		"Pick your products and order in a few clicks. Payment is secure",
	  ],
	  "order.deliveroo.title": "On Deliveroo",
	  "order.deliveroo.content": [
		"Quick delivery - Precise tracking",
		"Service and delivery fees can apply",
	  ],
	  "order.uber.title": "On Uber Eats",
	  "order.uber.content": [
		"Quick delivery - Precise tracking",
		"Service and delivery fees can apply",
	  ],
	  "order.telephone.title": "By phone",
	  "order.telephone.content": [
		"Ordering by phone is not available anymore to increase drivers safety and allow more time to prepare the orders",
	  ],
	  "order.url": "how-to-order",
	  "order.metaDescription": "How to get a fast alcohol delivery in " + process.env.REACT_APP_CITY + "? Order online and be delivered day and night in 30 minutes!",
	  "concept.title": "Concept",
	  "concept.idea.title": "The idea",
	  "concept.idea.subtitle": "An improvised party, some friends comming unannonced or just to time to go shopping? What about a home delivery?!",
	  "concept.idea.content": [
		"N°1 of alcohol and drinks delivery in Annecy since 2011, we deliver everyting needed for the perfect party at home. Alcohol and non alcoholic beverages, wines, beers and snacks are brought to you in under 30' and right after the stores are closed",
		"",
		"Make your choice amoug more than 50 packs. There is enough for all tastes and budgets!",
	  ],
	  "concept.idea.link": "Question? Check out our FAQ",
	  "concept.how.title": "How it works",
	  "concept.how.subtitle": "Make your choice, fill your address in and be delivered in 15-30 minutes!",
	  "concept.how.content": [
		"Choose to order online or on delivery plateforms, you'll always getting a quick delivered, well prepared package with products at the right temperature.",
		"",
		"Order when ever you like for a delivery from 7pm and until late at night!",
	  ],
	  "concept.how.link": "See our opening times",
	  "concept.about.title": "About us",
	  "concept.about.subtitle": "SOS Soirée is before all a team of <strike>passionnates</strike> super heros of 'apéro'!",
	  "concept.about.content": [
		"Founded in 2011 by students from Annecy, the little company of drinks delivery has grown up!",
		"",
		"In 2013, the by out of its main competitor Annecy Apero makes SOS Soirée the leader of alcohol delivery in Annecy.",
		"",
		"Today, as SOS Apéro, Alerte Apéro or Speed Apéro do in other cities, we have the largest selection of alcohols, beers, wines and other drinks available day and night in Annecy and suroundings.",
	  ],
	  "concept.about.follow": "Follow us!",
	  "concept.about.facebook": "https://www.facebook.com/sossoiree.annecy",
	  "concept.about.instagram": "https://www.instagram.com/sossoiree_annecy",
	  "hours.title": "Opening hours",
	  "hours.error": "Oops... It seems we failed to remember our opening hours...",
	  "hours.url": "opening-hours",
	  "welcomeCall.content": ["We took the liberty to log your account in from your call", "You now just have to pick the products of your choice and proceed with your order"],
	  "welcomeCall.contact": ["In case you need us, feel free to reach out by clicking the icon", "on the top right of your screen"],
	  "welcomeLegacy.title": "News - Order by phone",
	  "welcomeLegacy.content": ["From january 24, we don't take orders by phone anymore.", "Orders must be made here online or on our"],
	  "welcomeLegacy.link": "partner delivery plateforms",
	  "welcomeLegacy.footer": ["The SOS Soirée team looks forward to receive your order", "See you soon!"],
	  "cms.cgv": "Terms of service",
	  "cms.cgv.url": "/content/3-terms-of-service",
	  "cms.legal": "Legal terms",
	  "cms.legal.url": "/content/2-legal-terms",
	  "cms.privacy": "Privacy policy",
	  "cms.privacy.url": "/content/26-privacy-policy",
	  "deliveryConfirm.title": "OK let's summarize, just to be sure",
	  "deliveryConfirm.address": "You wish to be delivered at",
	  "deliveryConfirm.phone": "If needed, you'll be reachable at",
	  "deliveryConfirm.alcohol": "Selling alcohol in public spaces is forbidden. By continuing, you certify that the address is a private living or working space that you have the right to access.",
	  "cookieBanner.title": "Cookies at parties?",
	  "cookieBanner.subtitle": "We personally prefer mini pizzas!",
	  "cookieBanner.content": "But to function correctly, this site needs to use functionnal and analytics cookies. We don't use third parties cookies with advertising purposes.",
	  "cookieBanner.privacy": "Fore more info, check our",
	  "cookieBanner.accept": "Accepter et fermer",
	  "cookieBanner.warning": "It seems that your browser refuses all cookies, this site could not work correctly",
	  "error404.title": "Only water here...",
	  "error404.subtitle": "You must be in the wrong place, try another path",
	  "error500.title": "Houston? We may have a problem with french 'apéro'",
	  "error500.subtitle": "We're currently facing some issues, please try again in a few",
	  "button.resume": "Resume",
	  "button.next": "Next",
	  "button.edit": "Edit",
	  "button.save": "Save",
	  "button.send": "Send",
	  "button.letsGo": "OK let's go!",
	  "button.acceptAndContinue": "Accept and go on",
	  "navbar.backTo/": "Home",
	  "navbar.backTo/cart": "Cart",
	  "navbar.backTo/delivery": "Delivery address",
	  "navbar.backTo": "Back",
	  "toast.oopsError": "Oups... Something wrong happend 😕 Please try again",
	  "toast.product_out_of_stock": "Oops... we don't have enough of this product 😕",
	  "toast.add_product_failed": "We could't add the product to your cart, it may be unvailable 😕",
	  "toast.errorInput": "Input is incorrect, please check",
	  "toast.minOrderForAddress": "Minimal order for this address: {{min}}. Please modify your cart",
	  "toast.addressOutsideRange": "This address is outside our delivery zone, sorry 😕",
	  "toast.errorDisplayOrder": "We can't display this order, sorry 😕",
	  "toast.errorCartProduct": "Your cart has been updated since some products weren't available",
	  "toast.errorMinOrder": "Your cart is below the minimal order, please add products or change address",
	  "toast.errorAddressUnavailable": "The address selected is unavailable, please select an other one",
	  "toast.errorCartUpdate": "The cart has been updated, please check and confirm again",
	  "url.delivery": "delivery",
    }
  },
  fr: {
    translation: {
      "global.home": "Accueil",
	  "global.cart": "Panier",
	  "global.profile": "Mon compte",
	  "global.item": "produit",
	  "global.items": "produits",
	  "global.cgv": "Conditions Générales",
	  "global.cgu": "Politique de confidentialité",
	  "global.deliveryAddress": "Adresse de livraison",
	  "global.deliveryFee": "Frais de livraison",
	  "global.deliveryIn": "Livraison dans",
	  "global.deliveryAt": "Livraison à",
	  "global.delivery": "Livraison",
	  "global.deliveryScheduled": "Livraison prévue",
	  "global.dateAt": "à",
	  "global.dateBackAt": "De retour à",
	  "global.dateBack": "De retour",
	  "global.serviceClosed": "Fermé",
	  "global.serviceClosedExcep": "Fermé exceptionnellement",
	  "global.errorDeliveryTime": "Nous n'avons pas pu calculer une heure de livraison",
	  "global.addressOutsideRange": "Adresse en dehors de la zone de livraison",
	  "global.orderNum": "Commande n°",
	  "global.contactSupport": "Contacter le support client",
	  "global.pageRootTitle": "SOS Soirée",
	  "global.pageDefaultTitle": "Nos apéritifs livrés chez vous à " + process.env.REACT_APP_CITY,
	  "global.metaDescription": "Livraison d'apéritifs à domicile à "+ process.env.REACT_APP_CITY + ". Faites vous livrer alcool et sans alcool, vins, bières, packs cocktails et apéro à grignoter directement à la maison !",
	  "form.firstname": "Prénom",
	  "form.lastname": "Nom",
	  "form.phone": "Numéro de téléphone",
	  "footer.order": ["Comment", "commander ?"],
	  "menu.titleOur": "Nos",
	  "menu.titleDrinks": "apéritifs",
	  "menu.titleDelivered": "livrés",
	  "menu.titleToYou": "chez vous",
	  "menu.ourProducts": "Nos produits",
	  "menu.alcohol": "La vente d'alcool est interdite aux mineurs, personnes manifestement ivres ainsi que sur la voie publique. À consommer avec modération",
	  "home.description": "Se faire <b>livrer l'apéro à " + process.env.REACT_APP_CITY + "</b> ? Rien de plus simple avec <b>SOS Soirée</b> ! Choisissez parmi notre large sélection d'alcools, bières, vins, boissons sans alcool, apéritifs salés et snacks sucrés : <b>votre commande d'apéro livrée</b> de nuit comme de jour <b>en 30 minutes à " + process.env.REACT_APP_CITY + "</b> et alentours !",
	  "home.categoryMetaDescription": "Livraison de {{categoryName}} à " + process.env.REACT_APP_CITY,
	  "home.productMetaDescription": "Livraison de {{productName}} à " + process.env.REACT_APP_CITY,
	  "cart.title": "Panier",
	  "cart.my_cart": "Mon panier",
	  "cart.total_products": "Total produit(s)",
	  "cart.total": "Total",
	  "cart.total_delivery": "Frais de livraison",
	  "cart.total_discount": "Réduction",
	  "cart.proceed": "Commander",
	  "cart.your_cart_is_empty": "Ce panier est vide. Trop vide.",
	  "product.out_of_stock": "Indisponible",
	  "product.seeCart": "Voir le panier",
	  "product.addedToCart": "Ajouté au panier !",
	  "addToCart.add": "Ajouter au panier",
	  "login.welcome": "Bienvenue chez SOS Soirée",
	  "login.enterPhone": "Saisissez votre numéro pour vous connecter/enregistrer",
	  "login.enterCode": "Saissisez le code reçu par SMS",
	  "login.enterInfos": "Encore quelques petites infos 🙏",
	  "login.accept": "En continuant, vous acceptez notre",
	  "login.resend": "Renvoyer un code",
	  "login.resendIn": "dans",
	  "login.resendSecs": "secondes",
	  "login.errorCodeInvalid": "Le code saisi est incorrect",
	  "login.errorCodeExpired": "Ce code a expiré, veuillez cliquer sur Renvoyer",
	  "login.errorCodeAlready": "Ce code a déjà été vérifié, veuillez cliquer sur Renvoyer",
	  "login.errorPhoneMobile": "Veuillez saisir un numéro de téléphone mobile valide",
	  "login.loadingDefault": "Encore quelques instants et ensuite : APÉRO",
	  "login.loadingCustomer": "Heureux de vous revoir {{firstname}} ! Veuillez patientez, nous récupérons votre compte",
	  "deliveryAddress.title": "Adresse de livraison",
	  "deliveryAddress.switchAddress": "Sélectionner une autre adresse",
	  "deliveryAddress.addAddress": "Ajouter une adresse",
	  "deliveryAddress.errorNoAddress": "Veuillez sélectionner ou ajouter une adresse pour continuer",
	  "myAddresses.title": "Mes adresses",
	  "myAddresses.addAddress": "Ajouter une nouvelle adresse",
	  "addAddress.titleAdd": "Nouvelle adresse",
	  "addAddress.titleEdit": "Modifier une adresse",
	  "addAddress.subtitle": "Des informations précises permettent une livraison plus rapide ;-)",
	  "addAddress.address": "Adresse",
	  "addAddress.deliveryNotes": "Informations utiles pour le livreur",
	  "payment.title": "Paiement",
	  "payment.paymentMeans": "Moyens de paiement",
	  "payment.proceed": "Valider le paiement",
	  "payment.new": "Nouveau",
	  "payment.cgv": "J'ai lu et j'accepte les",
	  "payment.alcohol": "La vente d'alcool est interdite aux mineurs, personnes manifestement ivres ainsi que sur la voie publique. En continuant, vous attestez sur l'honneur respecter ces conditions et pouvoir fournir un justificatif d'identité à la livraison.",
	  "payment.acceptCgv": "Veuillez accepter les conditions générales de vente pour continuer",
	  "payment.deliveryTimeChanged": "Le délai de livraison a été mis à jour, veuillez vérifier et valider à nouveau s'il vous convient",
	  "payment.serviceClosed": "Service actuellement fermé, la commande n'est pas possible pour le moment",
	  "payment.orderUnavailable": "Commande impossible pour le moment, veuillez réessayer plus tard",
	  "payment.paymentRefused": "Paiement refusé, veuillez réessayer",
	  "payment.paymentTimeout": "Paiement annulé suite à une trop longue inactivité, veuillez recommencer",
	  "payment.paymentNotAvailable": "Ce moyen de paiement n'est pas encore disponible. Nous travaillons d'arrache-pied pour qu'il le soit très bientôt 🥵",
	  "payment.pleaseWait": "Chargement en cours, veuillez ne pas quitter cette page",
	  "payment.qa": {"q": "Pourquoi n'est-il pas possible de payer à la livraison ?", "a": "Pour garantir la sécurité des livreurs, nous ne proposons que le paiement en ligne. Si vous préférez, il est également possible de commander sur les plateformes de livraison :", "l": {"val": "Voir les autres moyens de commander", "onClickVal": "props.setOpenOrder(true);"}},
	  "orderDetail.title": "Ma commande",
	  "orderDetail.orderReceived": "Merci pour votre commande !",
	  "contact.title": "Nous contacter",
	  "contact.subtitle": "Notré équipe répond généralement dans les 48h pendant les jours ouvrés.",
	  "contact.sentTitle": "Nous avons bien reçu votre message, merci.",
	  "contact.sentSubtitle": "Notre équipe vous répond au plus vite",
	  "contact.faqTitle": "Votre réponse s'y trouve peut-être !",
	  "contact.faqInfos": "Consultez notre FAQ",
	  "faq.title": "FAQ",
	  "faq.qa": {
		"Produits": [
			{"q": "Quel type de produits livrez-vous ?", "a": "Nous livrons tout le nécessaire pour une soirée réussie : alcools, boissons non alcoolisées, vins, bières et de quoi grignoter à l'apéro", "l": {"val": "Découvrir la sélection", "to": "/"}},
			{"q": "Les produits sont ils livrés frais ?", "a": "Oui, tous nos produits sont livrés prêts à être consommés"},
			{"q": "Livrez-vous également des cigarettes ?", "a": "Non, il est interdit de livrer du tabac"},
			{"q": "Quels sont vos horaires ?", "a": "", "l": {"val": "Voir les horaires d'ouverture", "data-bs-toggle": "modal", "data-bs-target": "#horaires"}},
		],
		"Livraison": [
			{"q": "Quel est le délai de livraison ?", "a": "Le délai de livraison est généralement de 15-30 minutes mais peut varier en fonction de votre adresse, de l'affluence et des conditions de circulation. Renseignez votre adresse et le délai vous sera indiqué avant le paiement"},
			{"q": "Quelle est la zone de livraison ?", "a": "Nous livrons autour d'Annecy dans un rayon de 10-15km. La zone de livraison peut varier en fonction des conditions de circulation, de l'affluence ou de la météo."},
			{"q": "Comment savoir si vous livrez à mon adresse ?", "a": "Renseignez votre adresse après avoir validé votre panier, nous vous indiquerons si vous êtes en dehors de la zone de livraison"},
		],
		"Paiement": [
			{"q": "Quels moyens de paiement acceptez-vous ?", "a": "Nous acceptons toutes les cartes bancaires classiques. Seul le paiement en ligne au moment de la commande est possible"},
			{"q": "Est-il possible de payer en espèces ?", "a": "Par sécurité, nos livreurs n'encaissent pas d'espèces sur place, seul le paiement en ligne est possible"},
		],
		"Commande": [
			{"q": "Faut-il être majeur pour commander ?", "a": "Si votre commande contient de l'alcool, oui. Une pièce d'identité vous sera demandée par le livreur pour vous remettre votre livraison"},
			{"q": "Faut-il être dans un domicile pour commander ?", "a": "Si votre commande contient de l'alcool, oui. Aucune livraison d'alcool ne peut être faite sur la voie publique"},
			{"q": "Est-il possible de commander par téléphone ?", "a": "La prise de commande se fait désormais uniquement en ligne ou via les plateformes de livraison Deliveroo et Uber Eats"},
		],
		"Annulation": [
			{"q": "Est-il possible d'annuler une commande ?", "a": "Une fois la commande validée, il n'est pas possible de l'annuler."},
			{"q": "Que se passe t-il si je ne suis pas chez moi ou que je ne suis pas joignable ?", "a": "Le livreur a pour consigne d'attentre 10 minutes et d'essayer de vous joindre plusieurs fois. Si malgré cela, il n'est pas possible de vous livrer, la commande sera annulée. Les frais de livraison ainsi que 5€ de frais d'annulation vous seront facturés"},
			{"q": "Que faire si ma commande ne m'a pas été livrée ?", "a": "Dans de très rares cas, il est possible que nous ne puissions pas livrer votre commande (problème technique, rupture de stock, pas de livreur disponible, etc.). Dans ce cas, vous n'avez rien à faire, votre commande sera annulée et vous serez remboursé(e) automatiquement"},
		],
	  },
	  "faq.contactTitle": "Vous n'avez pas trouvé votre réponse ?",
	  "faq.contactInfos": "Contactez nous",
	  "order.title": "Comment commander",
	  "order.online.title": "En ligne ici",
	  "order.online.content": [
		"Le plus simple. Livraison rapide, aucun frais de service",
		"Choisissez vos produits et commandez en quelques clics. Payez en toute sécurité",
	  ],
	  "order.deliveroo.title": "Sur Deliveroo",
	  "order.deliveroo.content": [
		"Livraison rapide - Suivi précis",
		"Des frais de service et de livraison peuvent s'appliquer",
	  ],
	  "order.uber.title": "Sur Uber Eats",
	  "order.uber.content": [
		"Livraison rapide - Suivi précis",
		"Des frais de service et de livraison peuvent s'appliquer",
	  ],
	  "order.telephone.title": "Par téléphone",
	  "order.telephone.content": [
		"La commande par téléphone n'est plus disponible pour permettre plus de sécurité aux livreurs et plus de temps accordé à la préparation des commandes",
	  ],
	  "order.url": "comment-commander",
	  "order.metaDescription": "Commment se faire livrer de l'alcool rapidement à " + process.env.REACT_APP_CITY + " ? Commandez en ligne, livraison en 30 minutes de nuit comme de jour !",
	  "concept.title": "Le concept",
	  "concept.idea.title": "L'idée",
	  "concept.idea.subtitle": "Une soirée imprévue, des amis débarquent à l'improviste ? Et si on vous livrait à domicile ?!",
	  "concept.idea.content": [
		"N°1 de la livraison d'alcool et d'apéro à Annecy depuis 2011, nous vous livrons tout le nécessaire pour réussir vos apéros à domicile. Alcools et boissons non alcoolisées, vins, bières et apéro à grignoter vous sont livrés en moins de 30 minutes et dès la fermeture des magasins.",
		"",
		"Choisissez parmi plus de 50 assortiments en stock. Il y en a pour tous les goûts et tous les budgets !",
	  ],
	  "concept.idea.link": "Une question ? Consultez notre FAQ",
	  "concept.how.title": "Le fonctionnement",
	  "concept.how.subtitle": "Choisissez vos produits, renseignez votre adresse et soyez livrés dans 15-30 min !",
	  "concept.how.content": [
		"Que vous préfériez commander en ligne ou via les plateformes de livraison, choisir SOS Soirée, c'est l'assurance d'une commande préparée avec soin, livrée rapidement et contenant des produits à température de consommation.",
		"",
		"Commandez quand vous le souhaitez pour un livraison à partir de 19h et jusqu'à tard dans la nuit !",
	  ],
	  "concept.how.link": "Voir les horaires d'ouverture",
	  "concept.about.title": "À propos de nous",
	  "concept.about.subtitle": "SOS Soirée, c'est avant tout une équipe de <strike>passionnés</strike> super-héros de l'apéro !",
	  "concept.about.content": [
		"Fondée en 2011 par des étudiants d'Annecy, la petite société de livraison d'apéritifs a bien grandi !",
		"",
		"En 2013, le rachat de son principal concurrent Annecy Apéro propulse SOS Soirée au rend de leader de la livraison d'alcool à Annecy.",
		"",
		"Aujourd'hui, à l'instar de SOS Apéro, Alerte Apéro et autre Speed Apéro dans d'autres villes, nous oeuvrons à la livraison du plus large choix d'alcools, bières, vins et autres boissons de nuit comme de jour sur Annecy et alentours.",
	  ],
	  "concept.about.follow": "Suivez nous !",
	  "concept.about.facebook": "https://www.facebook.com/sossoiree.annecy",
	  "concept.about.instagram": "https://www.instagram.com/sossoiree_annecy",
	  "hours.title": "Horaires",
	  "hours.error": "Oups... On dirait qu'on arrive pas à se rappeler de nos horaires...",
	  "hours.url": "horaires",
	  "welcomeCall.content": ["On s'est permis de vous authentifier grace à votre appel et de vous connecter à votre compte.", "Il ne vous reste plus qu'à choisir ce qui vous ferait plaisir et valider votre commande"],
	  "welcomeCall.contact": ["En cas de besoin, n'hésitez pas à nous contacter via l'icone", "en haut à droite de votre écran"],
	  "welcomeLegacy.title": "Nouveauté - Commande par téléphone",
	  "welcomeLegacy.content": ["A compter de janvier 2024, nous ne prenons plus de commandes par téléphone.", "La commande se fait désormais exclusivement sur ce site ou sur les"],
	  "welcomeLegacy.link": "plateformes de livraison partenaires",
	  "welcomeLegacy.footer": ["Toute l'équipe SOS Soirée a hâte de recevoir et de préparer votre commande", "À très vite !"],
	  "cms.cgv": "Conditions générales de vente",
	  "cms.cgv.url": "/content/3-conditions-generales-de-vente",
	  "cms.legal": "Mentions légales",
	  "cms.legal.url": "/content/2-mentions-legales",
	  "cms.privacy": "Politique de confidentialité",
	  "cms.privacy.url": "/content/26-politique-de-confidentialite",
	  "deliveryConfirm.title": "OK récapitulons, juste pour être sûrs",
	  "deliveryConfirm.address": "Vous souhaitez être livré au",
	  "deliveryConfirm.phone": "Si besoin, vous serez joignable au",
	  "deliveryConfirm.alcohol": "La vente d'alcool est interdite sur la voie publique. En continuant, vous certifiez que l'adresse indiquée est un lieu privé (habitation ou travail) auquel vous avez accès.",
	  "cookieBanner.title": "Les cookies à l'apéro ?",
	  "cookieBanner.subtitle": "Nous on préfère les mini pizzas !",
	  "cookieBanner.content": "Mais pour fonctionner correctement, ce site nécessite le dépot de cookies fonctionnels et d'analyse. Nous n'utilisons aucun cookie tiers à des fins publicitaires.",
	  "cookieBanner.privacy": "Pour plus d'infos, consultez notre",
	  "cookieBanner.accept": "Accepter et fermer",
	  "cookieBanner.warning": "Il semble que votre navigateur refuse tous les cookies. Ce site pourrait ne pas fonctionner correctement",
	  "error404.title": "Il n'y a que de l'eau ici...",
	  "error404.subtitle": "Vous devez être au mauvais endroit, essayez un autre chemin",
	  "error500.title": "Houston ? On dirait qu'on a un problème avec l'apéro",
	  "error500.subtitle": "On rencontre actuellement un problème, veuillez réessayer dans quelques instants",
	  "button.resume": "Continuer",
	  "button.next": "Suivant",
	  "button.edit": "Modifier",
	  "button.save": "Enregistrer",
	  "button.send": "Envoyer",
	  "button.letsgo": "OK, c'est parti !",
	  "button.acceptAndContinue": "Accepter et continuer",
	  "navbar.backTo/": "Accueil",
	  "navbar.backTo/cart": "Panier",
	  "navbar.backTo/delivery": "Adresse de livraison",
	  "navbar.backTo": "Retour",
	  "toast.oopsError": "Oups... Une erreur est survenue 😕 Veuillez réessayer",
	  "toast.product_out_of_stock": "Oups... on a plus assez de ce produit 😕",
	  "toast.add_product_failed": "Nous n'avons pas pu ajouter ce produit au panier, il n'est peut-être plus disponible 😕",
	  "toast.errorInput": "Veuillez vérifier les données saisies",
	  "toast.minOrderForAddress": "Minimum de commande pour cette adresse : {{min}}. Merci de modifier votre panier",
	  "toast.addressOutsideRange": "Désolés, cette adresse est en dehors de notre zone de livraison 😕",
	  "toast.errorDisplayOrder": "Désolés, on ne peut pas afficher cette commande 😕",
	  "toast.errorCartProduct": "Votre panier a été mis à jour car des produits n'étaients plus disponibles",
	  "toast.errorMinOrder": "Votre panier est inférieur au minimum de commande. Veuillez ajouter des produits ou sélectionner une autre adresse",
	  "toast.errorAddressUnavailable": "L'adresse sélectionnée n'est plus disponible, veuillez en sélectionner une autre",
	  "toast.errorCartUpdate": "Le panier a été mis à jour, veuillez vérifier et valider à nouveau",
	  "url.delivery": "livraison",
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "fr", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;