import { useContext, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import PuffLoader from "react-spinners/PuffLoader";
import _ from "lodash";
import Cookies from 'universal-cookie'

import { GlobalStateContext } from '../Site.js'
import { Api } from '../API.js'
import Cart from '../classes/Cart.js'
import UserLocation from '../classes/UserLocation.js'

import WelcomeCall from '../components/WelcomeCall.js'

function FromCall(props) {
	
	const { globalState, setGlobalState } = useContext(GlobalStateContext);
	const [customer, setCustomer] = useState(null);
	const [openWelcome, setOpenWelcome] = useState(false);
	
	const { code } = useParams();
	const navigate = useNavigate();
	
	const verifCall = () => {
		Api(
			"loginClientByVerifAppel", 
			{
				"code": code
			},
			(data) => {
				let userLocation = globalState.userLocation;
				if(Array.isArray(data.adresses) && (data.adresses.length > 0)) {
					userLocation = UserLocation.fromAddress(data.adresses[0]);
				}
				checkVoucher((newCart) => {
					setGlobalState(prevState => ({
						...prevState,
						customer: data,
						userLocation: userLocation,
						cart: newCart ?? prevState.cart,
					}));
					setCustomer(data);
				});
			},
			(idError) => {
				checkVoucher((_) => navigate('/'));
			},
			globalState.token,
			globalState.setToken,
		);
	};
	
	const checkVoucher = (then) => {
		if((globalState?.cart != null) && (globalState.cart.voucher == null)) {
			globalState.cart.checkVoucher(
				"TEL5",
				globalState,
				(voucher) => {
					globalState.cart.updateCart(false, globalState, setGlobalState, (cart) => {then(cart)}, (_) => {}); 
				},
				(_) => {
					then(null);
				}
			);
		}
		else
			then(null);
	};
	
	useEffect(() => {
		props.setReturnPage(null);
	}, []);

	useEffect(() => {
		if(customer != null) {
			setOpenWelcome(true);
		}
	}, [customer]);
	
	useEffect(() => {		
		if((globalState != null) && (globalState.cart != null)) {
			const cookies = new Cookies(null, { path: '/' });
			let idCart = cookies.get("idCart");
			if((idCart ?? 0) == globalState.cart.idCart) {
				if((globalState.customer == null) && (code != null)) {
					verifCall();
				}
				else {
					checkVoucher((_) => navigate('/'));
				}
			}
		}
	}, [globalState?.cart]);
	
	return(
		<div className="vh-100 my-auto overflow-auto">
			{!openWelcome &&
				<div className="w-100 h-100 d-flex align-items-center justify-content-center">
					<PuffLoader 
						color="#1D71B8"
						size="5rem"
					/>
				</div>
			}
			<WelcomeCall isOpen={openWelcome} setIsOpen={setOpenWelcome} />
		</div>
  );
}

export default FromCall;
