import { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from "react-router-dom";
import dateFormat from "dateformat"
import PuffLoader from "react-spinners/PuffLoader";

import { GlobalStateContext } from '../Site.js'
import { Api } from '../API.js'
import { currencyFormat } from '../tools/NumberTools.js'
import { showToast } from '../tools/Toast.js'
import { showTidio } from '../tools/Tidio.js'

import CartItem from '../components/CartItem.js'

function OrderDetail(props) {
	
	const { globalState, setGlobalState } = useContext(GlobalStateContext);
	const [order, setOrder] = useState(null);
	
	const { idOrder } = useParams();
	const { t } = useTranslation();
	const navigate = useNavigate();
	
	const getOrder = () => {
		Api(
			"getCommandeById",
			{
				"id_commande": idOrder,
			},
			(data) => {
				setOrder(data);
			},
			(idError) => {
				showToast(t("toast.errorDisplayOrder"));
				navigate('/');
			},
			globalState.token,
			globalState.setToken
		);
	}
	
	const printScheduledDate = () => {
		if((order.datePrevue != null) && (order.datePrevue.date != null)) {
			let dateScheduled = new Date(order.datePrevue.date?.split(".")[0]?.replace(/-/g, "/"));
			let now = new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Zurich"}));
			let diffInMinutes = Math.round((dateScheduled.getTime() - now.getTime()) / 1000 / 60);
			if((diffInMinutes < 1440) && ((diffInMinutes <= 60) || (dateScheduled.getHours() > now.getHours()) || (dateScheduled.getHours() <= 6))) {
				return t("global.deliveryScheduled") + " " + t("global.dateAt") + " " + dateFormat(dateScheduled, "HH:MM");
			}
			else {
				return t("global.deliveryScheduled") + " " + dateScheduled.toLocaleDateString("fr-FR", { weekday: 'long' }) + " " + t("global.dateAt") + " " + dateFormat(dateScheduled, "HH:MM");
			}
		}
	};
	
	const getNbProducts = () => {
		let nbProducts = 0;
		if((order.produits != null) && (Array.isArray(order.produits))) {
			order.produits.forEach((p, i) => {
				nbProducts = nbProducts + p.quantite;
			});
		}
		return nbProducts;
	};
	
	const getTotalProducts = () => {
		let total = 0;
		if((order.produits != null) && (Array.isArray(order.produits))) {
			order.produits.forEach((p, i) => {
				total = total + (p.produit.prixTTC * p.quantite);
			});
		}
		return total;
	};
	
	useEffect(() => {
		if((globalState != null) && (idOrder != null)) {
			getOrder();
		}
	}, [globalState.init, idOrder]);
	
	useEffect(() => {
		props.setReturnPage("/");
		props.setPageTitle(t("orderDetail.title"));
	}, []);
	
	return(
		<div className="vh-100 my-auto overflow-auto bg">
			{order != null &&
				<div className="row osahan-my-account-page border-secondary-subtle overflow-auto g-0">
					<div className="px-4 py-3">
					   <h5 className="offcanvas-title fw-bold">{t("orderDetail.title")}</h5>
					</div>
					<div className="col-lg-8 p-4 border-end">
						<div className="mb-3">
							<h5 className="fw-bold text-success mb-1">{t("orderDetail.orderReceived")}</h5>
							<p className="fw-normal">{printScheduledDate()}</p>
						</div>
						<div className="w-100 bg-white border rounded-4">
							<div>
								<div className="d-flex align-items-center justify-content-between bg-white p-3">
								   <div className="w-75">
									  <div className="d-flex align-items-center gap-3 osahan-mb-1">
										 <i className="bi bi-person fs-5"></i>
										 <div className="pe-4 overflow-hidden">
											<h6 className="fw-bold mb-1">{order.adresseLivraison.prenom + " " + order.adresseLivraison.nom}</h6>
										 </div>
									  </div>
								   </div>
								</div>
								<div className="d-flex align-items-center justify-content-between bg-white p-3">
								   <div className="w-75">
									  <div className="d-flex align-items-center gap-3 osahan-mb-1">
										 <i className="bi bi-house fs-5"></i>
										 <div className="pe-4 overflow-hidden">
											<h6 className="fw-bold mb-1">{order.adresseLivraison.adresse}</h6>
											<p className="text-muted text-truncate mb-0 small">{order.adresseLivraison.cp + " " + order.adresseLivraison.ville}</p>
										 </div>
									  </div>
								   </div>
								</div>
								<div className="d-flex align-items-center justify-content-between bg-white p-3">
								   <div className="w-75">
									  <div className="d-flex align-items-center gap-3 osahan-mb-1">
										 <i className="bi bi-info-square fs-5"></i>
										 <div className="pe-4 overflow-hidden">
											<h6 className="fw-bold mb-1">{order.adresseLivraison.complementAdresse}</h6>
										 </div>
									  </div>
								   </div>
								</div>
								<div className="d-flex align-items-center justify-content-between bg-white p-3">
								   <div className="w-75">
									  <div className="d-flex align-items-center gap-3 osahan-mb-1">
										 <i className="bi bi-telephone fs-5"></i>
										 <div className="pe-4 overflow-hidden">
											<h6 className="fw-bold mb-1">{order.adresseLivraison.telephone}</h6>
										 </div>
									  </div>
								   </div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4 bg-light p-4">
						<div className="card border rounded-4 mb-4">
						  <div className="card-header d-flex align-items-center justify-content-between py-3">
							 <div>
								<h6 className="fw-bold mb-1">{t("global.orderNum")} {order.idCommande}</h6>
								<p className="text-muted m-0">{getNbProducts()} {(getNbProducts() > 1 ? t("global.items") : t("global.item"))}</p>
							 </div>
						  </div>
						  <div className="card-body">
							 {order.produits.map(p => {
									return <CartItem product={p.produit} quantity={p.quantite} userInteraction={false} key={p.produit.idProduit} />
							   })}
						  </div>
						</div>
						<div className="p-4 bg-light cart-total">
							<div className="d-flex align-items-center justify-content-between mb-1">
								<p className="text-muted m-0">{t("cart.total_products")} :</p>
								<p className="m-0">{currencyFormat(getTotalProducts())}</p>
							</div>
							<div className="d-flex align-items-center justify-content-between mb-1">
								<p className="text-muted m-0">{t("cart.total_delivery")} :</p>
								<p className="m-0">{currencyFormat(order.fraisLivraison)}</p>
							</div>
							<div className="d-flex align-items-center justify-content-between mb-1">
								<h6 className="fw-bold m-0">{t("cart.total")} :</h6>
								<p className="fw-bold text-info m-0">{currencyFormat(order.montantPaye)}</p>
							</div>
						</div>
						<div className="d-flex justify-content-center">
							<Link onClick={showTidio} className="btn btn-danger fw-bold py-3 px-4 w-75 rounded-4 shadow"><i className="icofont-user-alt-5 me-2"></i>{t("global.contactSupport")}</Link>
						</div>
					</div>
				</div>
		   }
		   {order == null &&
				<div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
					<PuffLoader 
						color="#1D71B8"
						size="5rem"
					/>
				</div>
		   }
		</div>
	);
}
export default OrderDetail;