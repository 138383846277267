import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PuffLoader from "react-spinners/PuffLoader";
import Cookies from 'universal-cookie';
import { useTranslation, Trans } from 'react-i18next'

import ProductList from '../components/ProductList.js'
import ProductDetail from '../components/ProductDetail.js'
import WelcomeLegacy from '../components/WelcomeLegacy.js'
import Order from '../components/Order.js'
import Icon18 from "../components/Icon18.js";

import { GlobalStateContext } from '../Site.js'
import { Api } from '../API.js'

import $ from 'jquery'
window.jQuery = $;
window.$ = $;

function Home(props) {
	
	const { globalState, setGlobalState } = useContext(GlobalStateContext);
	
	const [productSelected, setProductSelected] = useState(null);
	const [openProductDetail, setOpenProductDetail] = useState(false);
	const [openWelcomeLegacy, setOpenWelcomeLegacy] = useState(false);
	const [openOrder, setOpenOrder] = useState(props.showOrder ?? false);
	const [openHours, setOpenHours] = useState(props.showHours ?? false);
	
	const { idCategory, idProduct } = useParams();
	
	const location = useLocation();
	const navigate = useNavigate();
	const { t } = useTranslation();
	
	const selectProduct = (product) => {
		setProductSelected(product);
		setOpenProductDetail(true);
	}
	
	useEffect(() => {
		props.setReturnPage(null);
	}, []);
	
	useEffect(() => {
		if((globalState?.welcomeLegacyShown != null) && !globalState.welcomeLegacyShown && (idProduct == null) && (idCategory == null) && !openOrder && !openHours) {
			setOpenWelcomeLegacy(true);
			setGlobalState(prevState => ({
				...prevState,
			welcomeLegacyShown: true
			}));
			const cookies = new Cookies(null, { path: '/' });
			cookies.set("wl", true, { expires: new Date(new Date().getTime()+(6*60*60*1000)) });
		}
	}, [globalState?.welcomeLegacyShown]);
	
	useEffect(() => {
		if(props.showOrder) {
			props.setPageTitle(t("order.title"));
			props.setMetaDescription(t("order.metaDescription"));
		}
		else if(props.showHours) {
			$("#" + t("hours.url")).modal('show');
			$("#" + t("hours.url")).off("hidden.bs.modal").on("hidden.bs.modal", function () {
				navigate('/');
				props.setPageTitle(null);
				$("#" + t("hours.url")).off("hidden.bs.modal");
			});
			props.setPageTitle(t("hours.title"));
		}
		else {
			props.setPageTitle(null);
			props.setMetaDescription(null);
		}
		if(props.categories?.length > 0) {
			setTimeout(() => {
				if(idCategory != null) {
					let category = null;
					let i = 0;
					while((i < props.categories.length) && (category == null)) {
						if(props.categories[i].idCategorie == idCategory)
							category = props.categories[i];
						i++;
					}
					if(category != null) {
						document.getElementById("category-" + idCategory)?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
						props.setPageTitle(category.nom);
						props.setMetaDescription(t("home.categoryMetaDescription", { categoryName: category.nom.toLowerCase() }));
					}
				}
				else {
					if(idProduct != null) {
						let product = null;
						let i = 0;
						while((i < props.categories.length) && (product == null)) {
							let j = 0;
							while((j < props.categories[i].produits?.length) && (product == null)) {
								if(props.categories[i].produits[j].idProduit == idProduct)
									product = props.categories[i].produits[j];
								j++;
							}
							i++;
						}
						if(product != null) {
							selectProduct(product);
							props.setPageTitle(product.nom);
							props.setMetaDescription(t("home.productMetaDescription", { productName: product.nom }));
						}
						else {
							navigate('/');
						}
					}
				}
			}, 100);
		}
	}, [props.categories, idCategory, idProduct, props.showOrder]);
	
	return(
		<div className="vh-100 my-auto overflow-auto">
			{props.categories.map(category => {
				if(category.produits.length > 0)
					return <ProductList categoryName={category.nom} products={category.produits} selectProduct={selectProduct} key={category.idCategorie} idCategory={category.idCategorie} />
			})}
			{props.categories.length == 0 &&
				<div className="w-100 h-100 d-flex align-items-center justify-content-center">
					<PuffLoader 
						color="#1D71B8"
						size="5rem"
					/>
				</div>
			}
			{props.categories.length > 0 &&
				<div className="mt-3 p-2 w-100" style={{background: "#e4e4e4"}}>
					<div className="d-flex flex-column justify-content-center align-items-center w-100 text-center container text-muted">
						<p><Trans i18nKey="home.description" components={{ b: <b /> }} /></p>
						<div className="d-flex align-items-center small">
							<Icon18/>
							<span className="ms-2">{t("menu.alcohol")}</span>
						</div>
					</div>
				</div>
			}
			<ProductDetail isOpen={openProductDetail} setIsOpen={setOpenProductDetail} product={productSelected} />
			<WelcomeLegacy isOpen={openWelcomeLegacy} setIsOpen={setOpenWelcomeLegacy} setOpenOrder={setOpenOrder} />
			<Order isOpen={openOrder} setIsOpen={setOpenOrder} />
		</div>
  );
}

export default Home;
