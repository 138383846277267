import { useState, useContext, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, unstable_usePrompt, useParams } from "react-router-dom";
import Cookies from 'universal-cookie'
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PuffLoader from "react-spinners/PuffLoader";

import { GlobalStateContext } from '../Site.js'
import { Api } from '../API.js'
import { currencyFormat } from '../tools/NumberTools.js'
import { showToast } from '../tools/Toast.js'
import Cart from '../classes/Cart.js'

import CartItem from '../components/CartItem.js'
import CheckoutForm from '../components/CheckoutForm.js'
import FAQItem from '../components/FAQItem.js'
import Order from '../components/Order.js'
import Icon18 from '../components/Icon18.js'
import PaymentError from '../components/PaymentError.js'
import CheckPayment from '../components/CheckPayment.js'

function Payment(props) {
	
	const { globalState, setGlobalState } = useContext(GlobalStateContext);
	const [address, setAddress] = useState(null);
	const [paymentLoading, setPaymentLoading] = useState(true);
	const [loadingText, setLoadingText] = useState(null);
	const [transactionData, setTransactionData] = useState(null);
	const [openOrder, setOpenOrder] = useState(false);
	const [cgvAccepted, setCgvAccepted] = useState(false);
	const [cgvError, setCgvError] = useState(false);
	const [paymentError, setPaymentError] = useState({
		isOpen: false,
		setIsOpen: (isOpen) => {
			setPaymentError(prevState => ({
				...prevState,
				isOpen: isOpen
			}))
		},
		message: null,
		code: null,
	});
	const [params, setParams] = useState(null);
	
	const { t } = useTranslation();
	const navigate = useNavigate();
	
	const returnUrl = window.location.href + "?token=" + globalState?.token + "&idCart=" + globalState?.cart?.idCart;
	
	const preventPageChange = useCallback((e) => {
		e.preventDefault();
		e.returnValue = true;
	});
	
	const proceedPayment = () => {
		if(!cgvAccepted) {
			showToast(t("payment.acceptCgv"));
			setCgvError(true);
			return;
		}
		setPaymentLoading(true);
		globalState.cart.deliveryTime = globalState.userLocation.deliveryTime;
		globalState.cart.updateCart(
			true, 
			globalState, 
			setGlobalState, 
			(_) => {
				initTransaction();
			}, 
			(idError) => {
				setPaymentLoading(false);
				switch(idError) {
					case 11:
					case 25:
					case 28:
						setPaymentLoading(true);
						getCustomer(
							(_) => {
								showToast(t("toast.oopsError"));
								setPaymentLoading(false);
							},
							(_) => {
								showToast(t("toast.oopsError"));
								setPaymentLoading(false);
							}
						);
						break;
					case 34:
					case 35:
					case 42:
						showToast(t("toast.errorCartProduct"));
						navigate('/cart');
						break;
					case 51:
						showToast(t("toast.errorMinOrder"));
						break;
					case 62:
						setPaymentLoading(true);
						getCustomer(
							(_) => {
								showToast(t("toast.errorAddressUnavailable"));
								navigate('/delivery');
								setPaymentLoading(false);
							},
							(_) => {
								showToast(t("toast.oopsError"));
								setPaymentLoading(false);
							}
						);
						break;
					case 65:
						setPaymentLoading(true);
						getOrderByCart(
							(order) => {
								navigateToOrder(order.idCommande);
								setPaymentLoading(false);
							},
							(idError) => {
								globalState.cart.idCart = 0;
								proceedPayment();
							}
						);
						break;
					case 66:
						showToast(t("toast.addressOutsideRange"));
						navigate('/delivery');
						break;
					default:
						if(globalState.cart.idCart > 0) {
							globalState.cart.idCart = 0;
							proceedPayment();
						}
						else {
							showToast(t("toast.oopsError"));
						}
						break;
				}
			}
		);
	};
	
	const initTransaction = () => {
		Api(
			"initTransactionStripe",
			{
				"id_cart": globalState.cart.idCart,
				"total": globalState.cart.getTotal(),
			},
			(data) => {
				setTransactionData({
					clientSecret: data.clientSecret,
					stripePromise: loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY),
				});
			},
			(idError) => {
				setPaymentLoading(false);
				switch(idError) {
					case 36:
						setPaymentLoading(true);
						Cart.getCart(
							globalState.cart.idCart,
							globalState,
							(cart) => {
								if(cart.getTotal() != globalState.cart.getTotal()) {
									setGlobalState(prevState => ({
										...prevState,
										cart: cart,
									}));
									showToast(t("toast.errorCartUpdate"));										
								}
								else {
									showToast(t("toast.oopsError"));
								}
								navigate('/cart');
								setPaymentLoading(false);
							},
							(_) => {
								showToast(t("toast.oopsError"));
								setPaymentLoading(false);
							}
						);
						break;
					case 74:
					case 75:
						if(globalState.userLocation != null) {
							setPaymentLoading(true);
							globalState.userLocation.getDeliveryTime(
								globalState,
								setGlobalState,
								(deliveryTime) => {
									setPaymentLoading(false);
									if(globalState.cart.deliveryTime != null) {
										let now = new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Zurich"}));
										let diffFromNow = Math.round((deliveryTime.getTime() - now.getTime()) / 1000 / 60);
										let diffBetweenDelays = Math.round((deliveryTime.getTime() - globalState.cart.deliveryTime.getTime()) / 1000 / 60);
										if(diffFromNow < 60) {
											proceedPayment();
										}
										else {
											showToast(t("payment.deliveryTimeChanged"));
										}
									}
									else
										showToast(t("toast.oopsError"));
								},
								(idError) => {
									setPaymentLoading(false);
									if(idError == 73) {
										showToast(t("payment.serviceClosed"));
									}
									else {
										showToast(t("toast.oopsError"));
									}
								}
							);
						}
						else {
							showToast(t("toast.oopsError"));
						}
						break;
					default:
						setPaymentError(prevState => ({
							...prevState,
							isOpen: true,
							message: t("toast.oopsError"),
							code: "initTransaction_api_" + idError
						}));
						break;
				}
			},
			globalState.token,
			globalState.setToken
		);
	};
	
	const paymentReturn = (retriesLeft = 5, timeout = false) => {
		setPaymentLoading(true);
		setLoadingText(t("payment.pleaseWait"));
		Api(
			"paymentReturnStripe",
			{
				"id_cart": globalState.cart.idCart,
			},
			(data) => {
				navigateToOrder(data.idCommande);
			},
			(idError) => {
				if((idError == 82) && (retriesLeft > 0)) {
					paymentReturn(retriesLeft - 1);
				}
				else {
					switch(idError) {
						case 68:
							setPaymentLoading(true);
							getOrderByCart(
								(order) => {
									navigateToOrder(order.idCommande);
									setPaymentLoading(false);
									setLoadingText(null);
								},
								(_) => {
									setPaymentError(prevState => ({
										...prevState,
										isOpen: true,
										message: t("toast.oopsError"),
										code: "paymentReturn_api_" + idError
									}));
									setPaymentLoading(false);
									setLoadingText(null);
								}
							);
							break;
						case 82:
							if(!timeout) {
								setPaymentError(prevState => ({
									...prevState,
									isOpen: true,
									message: t("toast.oopsError"),
									code: "paymentReturn_api_" + idError
								}));
								break;
							}
							break;
						default:
							setPaymentError(prevState => ({
								...prevState,
								isOpen: true,
								message: t("toast.oopsError"),
								code: "paymentReturn_api_" + idError
							}));
							break;
					}
					setTransactionData(null);
					setPaymentLoading(false);
					setLoadingText(null);
				}
			},
			globalState.token,
			globalState.setToken
		);
	};
	
	const navigateToOrder = (idOrder) => {
		const cookies = new Cookies(null, { path: '/' });
		cookies.remove("idCart", { path: '/' });
		setGlobalState(prevState => ({
			...prevState,
			cart: Cart.emptyCart()
		}));
		navigate('/order/' + idOrder);
	};
	
	const getOrderByCart = (onSuccess, onFailure) => {
		Api(
			"getCommandeByPanier",
			{
				"id_panier": globalState.cart.idCart,
			},
			(data) => {
				if(data != null)
					onSuccess(data);
				else
					onFailure(0);
			},
			(idError) => {
				onFailure(idError);
			},
			globalState.token,
			globalState.setToken
		);
	};
	
	const getCustomer = (onSuccess, onFailure, token = globalState.token) => {
		Api(
			"getClientToken", 
			{},
			(data) => {
				setGlobalState(prevState => ({
					...prevState,
					customer: data
				}));
				onSuccess();
			},
			(idError) => {
				setGlobalState(prevState => ({
					...prevState,
					customer: null
				}));
				onFailure();
			},
			token,
			globalState.setToken,
		);
	};
	
	const isValid = () => {
		return(
			(globalState.cart.products.length > 0) && 
			(globalState.cart.deliveryFee != null) && 
			(globalState.cart.idAddress > 0) && 
			(globalState.userLocation?.idAddress == globalState.cart.idAddress) && 
			(globalState.userLocation?.deliveryTime != null)
		);
	};
	
	const getStripeOptions = () => {
		let appearance = {
			theme: 'stripe',
		};
		let clientSecret = transactionData?.clientSecret;
		let externalPaymentMethodTypes = ['external_postfinance'];
		return {
			clientSecret,
			appearance,
			externalPaymentMethodTypes,
		};
	}
			
	useEffect(() => {
		if((globalState?.cart.idAddress ?? 0) > 0) {
			if(Array.isArray(globalState.customer.adresses) && (globalState.customer.adresses.length > 0)) {
				let address = globalState.cart.getAddress(globalState) ?? globalState.customer.adresses[0];
				setAddress(address);
				if(globalState.cart.deliveryFee == null) {
					globalState.cart.getDeliveryMinAndFee(
						globalState, 
						setGlobalState, 
						(data) => {
							globalState.cart.deliveryFee = data.fraisLivraison;
							setGlobalState(prevState => ({
								...prevState,
								cart: globalState.cart
							}));
						}, 
						(_) => {}
					);
				}
			}
		}
	}, [globalState?.cart.idAddress]);
	
	useEffect(() => {
		window.removeEventListener("beforeunload", preventPageChange);
		if(paymentLoading) {
			window.addEventListener("beforeunload", preventPageChange);
		}
		return () => {
			window.removeEventListener("beforeunload", preventPageChange);
		};
	}, [paymentLoading]);
	
	useEffect(() => {
		if((globalState != null) && (params != null)) {
			const cookies = new Cookies(null, { path: '/' });
			if((globalState.customer == null) && (params.token != null) && (params.token != globalState.token)) {
				getCustomer(
					() => {
						globalState.setToken(params.token);
					},
					(_) => {
						navigate('/cart');
					},
					params.token
				);
			}
			else if((params.idCart != null) && (globalState.cart?.idCart != params.idCart)) {
				Cart.getCart(
					params.idCart,
					globalState,
					(cart) => {
						cookies.set("idCart", params.idCart, { expires: new Date(new Date().getTime()+(365*24*60*60*1000)) });
						setGlobalState(prevState => ({
							...prevState,
							cart: cart
						}));
					},
					(_) => {
						navigate('/cart');
					}
				);
			}
			else if((globalState.customer == null) || ((globalState.cart?.idCart == 0) && (cookies.get("idCart") == null) || (cookies.get("idCart") == 0))) {
				navigate('/cart');
			}
			else if(globalState?.cart?.idCart > 0) {
				setPaymentLoading(true);
				if(params.payment_intent_client_secret != null) {
					setTransactionData({
						clientSecret: params.payment_intent_client_secret,
						stripePromise: loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY),
						paymentReturn: true,
					});
					setLoadingText(t("payment.pleaseWait"));
				}
				else {
					getOrderByCart(
						(order) => {
							navigateToOrder(order.idCommande);
							setPaymentLoading(false);
						},
						(idError) => {
							setPaymentLoading(false);
						}
					);
				}
			}
		}
	}, [globalState?.customer, globalState?.cart?.idCart, params]);
	
	useEffect(() => {
		props.setReturnPage(null);
		props.setPageTitle(t("payment.title"));
		let url = new URL(window.location);
		let searchParams = new URLSearchParams(url.search);
		let params = {};
		searchParams.forEach((value, key) => {
			params[key] = value;
		});
		setParams(params);
	}, []);
		
	return(
		<div className="vh-100 my-auto overflow-auto" style={{overscrollBehavior: 'contain'}}>
			<div className="px-4 py-3">
			   <h5 className="offcanvas-title fw-bold">{t("payment.title")}</h5>
			</div>
			<div className="row osahan-my-account-page border-secondary-subtle g-0 d-flex justify-content-around">
				{globalState?.cart != null &&
					<div className="col-lg-8 flex-grow-1 d-flex justify-content-center px-4 py-lg-4">
						{(!paymentLoading && (transactionData == null)) &&
							<div className="col-lg-8 d-flex flex-column">
								<div className="w-100 p-3 bg-white rounded-4 shadow">
									{globalState?.userLocation?.idError > 0 &&
										<div className="d-flex justify-content-center align-items-center px-3 mb-3">
											<div className="bg-danger-subtle rounded-pill d-flex justify-content-center align-items-center px-5 py-3 flex-column gap-1 text-center">
												<p className="fw-bold mb-0">{globalState?.userLocation?.getErrorLabel(t)}</p>
												<p className="text-black m-0">{(globalState?.userLocation?.idError != 66 ? t("payment.orderUnavailable") : "")}</p>
											</div>
										</div>
									}
									<div className="border-bottom border-secondary-subtle p-3 mb-3 d-flex align-items-center justify-content-between">
										<div className="w-75">
											<h6 className="fw-bold mb-1">{t("global.deliveryAddress")}</h6>
											<p className="text-muted small m-0">{address?.adresse}</p>
										</div>
										<div className="ms-auto gap-2 text-center">
										  <Link to='/delivery' className="d-flex align-items-center gap-1 text-info"><i className="bi bi-pencil-square d-flex"></i></Link>
										</div>
									</div>
									<div className="p-3 bg-light mb-3 rounded-2">
										<div className="d-flex align-items-center justify-content-between mb-1">
										 <p className="text-muted m-0">{t("cart.total_products")}</p>
										 <p className="m-0">{currencyFormat(globalState?.cart?.getTotalProducts())}</p>
										</div>
										{globalState?.cart?.voucher != null &&
										  <div className="d-flex align-items-center justify-content-between mb-3">
											<div className="d-flex flex-column w-80">
											 <p className="text-muted m-0">{t("cart.total_discount")}</p>
											 <p className="text-muted text-truncate small m-0 ms-3">{globalState?.cart?.voucher.description}</p>
											</div> 
											 <p className="m-0">- {currencyFormat(globalState?.cart?.getTotalDiscount())}</p>
										  </div>
										}
										{globalState?.cart?.deliveryFee != null &&
										  <div className="d-flex align-items-center justify-content-between mb-3">
											 <p className="text-muted m-0">{t("cart.total_delivery")}</p>
											 <p className="m-0">{currencyFormat(globalState?.cart?.deliveryFee)}</p>
										  </div>
										}
										<div className="d-flex align-items-center justify-content-between">
											 <h6 className="fw-bold m-0">{t("cart.total")}</h6>
											 <p className="fw-bold text-info m-0">{currencyFormat(globalState?.cart?.getTotal())}</p>
										</div>
									</div>
									{globalState?.cart?.containsAlcohol() &&
										<div className="d-flex justify-content-center align-items-center p-3 mt-3 bg-light-gray rounded-2 mb-3">
											<Icon18 />
											<p className="ms-3 mb-0">{t("payment.alcohol")}</p>
										</div>
									}
									<div className={"px-5 py-2 form-check form-switch d-flex align-items-center " + (cgvError ? "cgv-error" : "")}>
										<input className="form-check-input border-dark cgv-input" type="checkbox" id="cgvCheck" role="button" checked={cgvAccepted} onChange={(e) => {setCgvAccepted(!cgvAccepted); setCgvError(false);}} />
										<label className="form-check-label ms-2 mt-1 flex-wrap" htmlFor="cgvCheck" role="button"
										>
											{t("payment.cgv")}
											<Link to={t("cms.cgv.url")} className="text-info ms-1 flex-wrap ">{t("cms.cgv").toLowerCase()}</Link>
										</label>
									</div>
								</div>
								<div className="py-3 position-relative mt-4">
									<div className="position-absolute right-0 bg-black text-white py-1 px-2 rounded-4" style={{right: "0", marginTop: "-1rem", marginRight: "1rem"}}>
										<span><i className="bi bi-star-fill me-1"></i>{t("payment.new")}</span>
									</div>
									<Link className={"btn btn-danger fw-bold d-flex align-items-center justify-content-center justify-content-lg-between py-3 px-4 w-100 rounded-4 shadow" + (!isValid() ? " cursor-default" : "")} onClick={(e) => proceedPayment()}>
										<span className="d-none d-lg-block">{currencyFormat(globalState?.cart?.getTotal())}</span>
										<span>{t("payment.paymentMeans")}<i className="icofont-double-right ms-1"></i></span>
									</Link>
								</div>
							</div>
						}
						{(paymentLoading || (transactionData != null)) &&
							<div className="w-100 h-100 p-lg-4 d-lg-flex justify-content-center">
								<div className="col-lg-8">
									{transactionData != null &&
										<Elements options={getStripeOptions()} stripe={transactionData.stripePromise}>
											{!transactionData.paymentReturn &&
												<CheckoutForm clientSecret={transactionData.clientSecret} returnUrl={returnUrl} paymentReturn={paymentReturn} paymentLoading={paymentLoading} setPaymentLoading={setPaymentLoading} setPaymentError={setPaymentError} 
													onLoadError={() => {
														setTransactionData(null);
														setPaymentLoading(false);
													}}
												/>
											}
											{transactionData.paymentReturn &&
												<CheckPayment clientSecret={transactionData.clientSecret} paymentReturn={paymentReturn} setPaymentError={(paymentError) => { setPaymentError(paymentError); setTransactionData(null); setPaymentLoading(false); }} />
											}
										</Elements>
									}
									{paymentLoading && 
										<div className="pt-5 h-100 d-flex flex-column align-items-center justify-content-center">
											<PuffLoader 
												color="#1D71B8"
												size="5rem"
											/>
											{loadingText != null &&
												<span className="text-info mt-4 text-center">{loadingText}</span>
											}
										</div>
									}
									{!paymentLoading &&
										<div className="d-flex flex-column mt-5">
											<div className="accordion accordion-flush" id="accordionFlushExample1">
												<FAQItem qa={t("payment.qa", { returnObjects: true })} k="1" p="1" setOpenOrder={setOpenOrder} />
											</div>
										</div>
									}
								</div>
							</div>
						}
						
					</div>
				}
				{globalState?.cart != null &&
					<div className="col-lg-4 p-4 d-none d-lg-block">
						<div className="border border-secondary-subtle rounded-4 mb-4">
						   <div className="bg-light d-flex align-items-center border-secondary-subtle justify-content-between border-bottom p-3">
							  <p className="fw-bold text-muted m-0">{t("global.cart")}</p>
							  <p className="m-0">{globalState?.cart?.getNbProducts()} {globalState?.cart?.getNbProducts() > 1 ? t("global.items") : t("global.item")}</p>
						   </div>
						   {globalState.cart.products.map(p => {
								return <CartItem product={p.product} quantity={p.quantity} userInteraction={false} key={p.product.idProduit} />
						   })}
						</div>
					  </div>
				}
			</div>
			<Order isOpen={openOrder} setIsOpen={setOpenOrder} />
			<PaymentError {...paymentError} />
		</div>
	);
}
export default Payment;